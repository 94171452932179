<template>
  <!-- 资讯详情 -->
	<div class="container">
		<div class="detail-img">
			<img :src="newsInfo.thumb?newsInfo.thumb:defaultSrc" />
		</div>
		<div class="detail-maincon">
			<div class="top">
				<div class="title">{{newsInfo.title}}</div>
				<div class="info">
					<!-- <div class="author">
						<img class="headimg" src="/static/imgs/head-2.jpg" />
						<text>艺集网</text>
					</div> -->
					<div class="update-info">
						<div class="item">							
              <i class="el-icon-view"></i>
							<span>{{newsInfo.views}}</span>
						</div>
						<div class="item">							
              <i class="el-icon-time"></i>
							<span>{{newsInfo.update_time | formatDate}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="bottom">
				<div class="maincon" v-html="newsInfo.content"></div>
        
			</div>
		</div>
	</div>
</template>

<script>
import { formatDate } from "@/utils/timeStamp.js";
export default {
  // 过滤时间
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy.MM.dd");
    },
  },
  data() {
    return {
      defaultSrc:require('@/assets/images/default.png'),      
      newsInfo:{}
    };
  },
  components: {},
  created() {
		let id = this.$route.query.id?this.$route.query.id:84;
		this.getNewsInfo(id)		
	},
  methods:{
    getNewsInfo(id) {
			this.$http
			.get('/api/wap/news/getNewsInfo?article_id='+id)
			.then((res) => {
				this.newsInfo = res.data.newsInfo
			})
		}
  }
};
</script>

<style lang='less' scoped>
.container{
	width: 100%;
	.detail-img{
		position: relative;
		width: 100%;
		height: auto;
		overflow: hidden;
		img{
			display: block;
			width: 100%;
      height: auto;
		}		
	}
	.detail-maincon{
		position: relative;
		display: block;
		width: 100%;
		padding:.35rem .30rem .30rem;
		background-color: #fff;
		margin-top: -0.35rem;
		border-radius: .35rem .35rem 0px 0px;
		.top{
			width: 100%;
			padding-bottom: .2rem;
			margin-bottom: .2rem;
			border-bottom: .02rem solid #f3f3f3;
			.title{
				font-size: .32rem;
				line-height: .6rem;
				font-weight: 700;
				text-align: left;
				color: #333333;
				margin-bottom: .2rem;
				text{
					color: #333333;
				}
			}
			.info{
				display: flex;
				width: 100%;
				align-items: center;
				justify-content: flex-start;
				.author{
					display: flex;
					align-items: center;
					height: .40rem;
					justify-content: space-between;
					.headimg{
						width: .40rem;
						height: .40rem;
						border-radius: .20rem;
						margin-right: .20rem;
					}
					text{
						font-size:.28rem;
						font-weight: 500;
						text-align: left;
						color: #333333;
					}
				}
				.update-info{
					display: flex;
					align-items: center;
					justify-content: flex-start;
					.item{
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-right: .30rem;
						span{
							font-size: .24rem;
							font-weight: 500;
							text-align: left;
							color: #cccccc;
							line-height: .40rem;
						}
						.el-icon-view,.el-icon-time{
							font-size: .24rem;
							line-height: .40rem;
							color: #ccc;
							margin-right: .05rem;
						}
					}
				}
			}
		}
		/deep/.bottom{
			width: 100%;
			overflow: hidden;
			.maincon{
				display: block;
				font-size: .26rem;
				font-weight: 500;
				text-align: left;
				color: #666666;
				line-height: .48rem;
			}
			img{
				display: block;
				width: auto;
				max-width: 100%;
				height: auto;
			}
		}
	}
}
</style>
